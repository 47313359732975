import Recaptcha from "react-google-invisible-recaptcha";
import React from "react";
import LazyLoad from "react-lazyload";
import { RiMailSendLine } from "react-icons/ri";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/layout";
import Obfuscate from "react-obfuscate";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      success: "",
      error: "",
      submitting: false,
      formValid: false,
      emailValid: null,
      nameValid: null,
      messageValid: null,
      showEmailError: false,
      showNameError: false,
      showMessageError: false,
      emailClass: "normal-input",
      nameClass: "normal-input",
      messageClass: "normal-input",
      disabled: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onResolved = this.onResolved.bind(this);
  }
  onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };
  validateField(fieldName, value) {
    let emailValid = this.state.emailValid;
    let nameValid = this.state.nameValid;
    let messageValid = this.state.messageValid;
    let emailClass, nameClass, messageClass;
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    switch (fieldName) {
      case "email":
        emailValid = re.test(value);
        emailClass = emailValid ? "is-valid" : "has-error";
        this.setState({ showEmailError: !emailValid });
        break;
      case "name":
        nameValid = value.length >= 1;
        nameClass = nameValid ? "is-valid" : "has-error";
        this.setState({ showNameError: !nameValid });
        break;
      case "message":
        messageValid = value.length >= 1;
        messageClass = messageValid ? "is-valid" : "has-error";
        this.setState({ showMessageError: !messageValid });
      default:
        break;
    }
    this.setState({
      emailValid: emailValid,
      nameValid: nameValid,
      messageValid: messageValid,
    });
    this.setState({
      emailClass: emailClass,
      nameClass: nameClass,
      messageClass: messageClass,
    });
    this.setState({
      formValid:
        this.state.emailValid &&
        this.state.nameValid &&
        this.state.messageValid,
    });
  }
  render() {
    const postUrl = "";
    const t = function (a) {
      return a;
    }; // useTranslation(); //
    //const c = useI18next();
    return (
      <div className="flex justify-center p-4 px-3 py-10 ">
        <div
          className={this.state.success === "" ? "w-full max-w-lg" : "hidden"}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitForm();
            }}
            className={
              this.state.success === "" ? "w-full max-w-xl" : "hidden-form"
            }
          >
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  <Trans>Name (Required)</Trans>
                </label>
                <input
                  className={
                    this.state.showNameError
                      ? "appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      : "appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  }
                  id="grid-first-name"
                  type="text"
                  name="name"
                  autocomplete="name"
                  placeholder={this.props.name}
                  onChange={this.onChange}
                />
                <p
                  className={
                    this.state.showNameError
                      ? "text-red-500 text-xs italic"
                      : "text-red-500 text-xs italic hidden"
                  }
                >
                  <Trans>Please fill out this field.</Trans>
                </p>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-email"
                >
                  <Trans>E-mail (Required)</Trans>
                </label>
                <input
                  className={
                    this.state.showEmailError
                      ? "appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      : "appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  }
                  id="grid-email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  placeholder={this.props.email}
                  onChange={this.onChange}
                />
                <p
                  className={
                    this.state.showEmailError
                      ? "text-red-500 text-xs italic"
                      : "text-red-500 text-xs italic hidden"
                  }
                >
                  <Trans>Please fill out this field.</Trans>
                </p>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-text"
                >
                  <Trans>Message (Required)</Trans>
                </label>
                <textarea
                  className={
                    this.state.showMessageError
                      ? "appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-64 resize-none"
                      : "appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-64 resize-none"
                  }
                  id="grid-text"
                  name="message"
                  required
                  onChange={this.onChange}
                />
                <p
                  className={
                    this.state.showMessageError
                      ? "text-red-500 text-xs italic"
                      : "text-red-500 text-xs italic hidden"
                  }
                >
                  <Trans>Please fill out this field.</Trans>
                </p>
              </div>
            </div>
            <div className="container mx-auto text-center mb-8">
              <button
                disabled={this.state.disabled}
                className="tracking-wider mx-auto block text-center inline-flex items-center shadow bg-green-700 text-white hover:bg-green-500 focus:shadow-outline focus:outline-none font-bold py-2 px-6 rounded shadow"
                type="button"
                onClick={this.onSubmit}
              >
                <RiMailSendLine style={{ marginRight: "15px" }} size={25} />
                <span>
                  {" "}
                  {this.state.disabled ? (
                    <Trans>Sending...</Trans>
                  ) : (
                    <Trans>Send</Trans>
                  )}
                </span>
              </button>
              <div className="md:w-2/3" />
            </div>
            <LazyLoad offset={500}>
              <Recaptcha
                ref={(ref) => (this.recaptcha = ref)}
                sitekey="6LcOZcwUAAAAAOoDszbf6qCvTuvIcKT3RiwaviIt"
                onResolved={this.onResolved}
                badge="inline"
                locale={this.props.language}
                once
              />
            </LazyLoad>
          </form>
        </div>
        <div
          className={
            this.state.success === ""
              ? "hidden"
              : this.state.success === "true"
              ? "visible bg-green-400 p-16"
              : "visible bg-red-500 p-16"
          }
        >
          <span>
            {this.state.success === "true" ? (
              <Trans>Your message has been sent. Thank you!</Trans>
            ) : (
              (
                <Trans>
                  There was an error sending the message. Please reload the page
                  and try again. (
                </Trans>
              ) +
              this.state.error +
              ")"
            )}
          </span>
        </div>
      </div>
    );
  }

  onSubmit() {
    if (
      this.state.emailValid &&
      this.state.nameValid &&
      this.state.messageValid
    ) {
      this.setState({ submitting: true });
      if (this.state.disabled) {
        return;
      }
      this.setState({ disabled: true });
      this.recaptcha.execute();
    } else {
      this.state.emailValid
        ? this.setState({ showEmailError: false })
        : this.setState({ showEmailError: true });
      this.state.nameValid
        ? this.setState({ showNameError: false })
        : this.setState({ showNameError: true });
      this.state.messageValid
        ? this.setState({ showMessageError: false })
        : this.setState({ showMessageError: true });
    }
  }
  onResolved() {
    var { name, email, message } = this.state;
    const token = this.recaptcha.getResponse();
    name = encodeURIComponent(name);
    email = encodeURIComponent(email);
    message = encodeURIComponent(message);

    fetch("/api/contact", {
      method: "POST",
      body:
        "name=" +
        name +
        "&email=" +
        email +
        "&message=" +
        message +
        "&recaptcha=" +
        token,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((resp) => resp.json())
      .then((data) =>
        this.setState({
          success: data.success,
          error: data.message,
        })
      )
      .catch((error) => {
        console.log(error);
        this.setState({ success: "false", error: "Internal Error" });
      });
  }
}
export default ContactForm;
