import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ContactForm from "../components/ContactForm";
import Obfuscate from "react-obfuscate";

import social_image from "../images/social.png";

import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { useI18next } from "gatsby-plugin-react-i18next";

function ContactPage() {
  const { t } = useTranslation();
  const c = useI18next();
  return (
    <Layout>
      <SEO
        keywords={[`contact`, "Diego Valle", "Diego Valle-Jones"]}
        title={t("Contact")}
        description={t(
          "I always enjoy hearing from my visitors. Don't hesitate to use the form to send me an email."
        )}
        image={social_image}
      />
      <main className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full">
        <section className="text-center">
          <h1>
            <Trans>Contact</Trans>
          </h1>
          <p className="text-left">
            <Trans i18nKey="contact_text">
              I enjoy reading interesting and insightful comments from my
              visitors. Nothing makes me happier than helping people out, so
              don’t hesitate to use the form below to get in touch with me or
              email me directly at <Obfuscate linkText="" email="diego@diegovalle.net" />{" "}
              (se habla español).
            </Trans>
          </p>
          <ContactForm language={c.language} name={t("John Smith")} email={t("example@example.com")}/>
          <hr className="p-5" />
        </section>
        <section className="text-left">
          <div>
            <h3 className="text-3xl">
              <Trans>Other ways to get in touch:</Trans>
            </h3>
            <br />
            Twitter:{" "}
            <a href="https://twitter.com/diegovalle" rel="me">
              @diegovalle
            </a>
            <br />
            <br />
            <a href="https://www.diegovalle.net/key.asc">PGP Key</a>
            <br />
            PGP Fingerprint: 76B3 74CF AFB9 E863 1313 83A8 55BD 9EC4 CD81 C252
            <br />
            {/* <b>Signal Fingerprint:</b> <pre>05 a9 32 e5 fd 12 0b 40
fc df c4 71 aa ad 27 4f
64 05 8a e8 86 7f 8e 08
ed 8e 12 17 72 34 94 b1
42</pre><br>*/}
          </div>
          <br />
          <div
            dangerouslySetInnerHTML={{
              __html: `<code class="whitespace-pre" style="font-family:'Lucida Console', Monaco, monospace;font-size: 13px; line-height: 10px">
-----BEGIN PGP PUBLIC KEY BLOCK-----

mQINBFNoG6gBEADUb3F7iVz7k6yt5oVipTv4F0kg6SUoBEPcU/sSwwu40XLyDRm2
fVOgdsr5lWYTpcq4JWOk7S27IOTknZpWMNetdb3XHHdATWoHSEln3acIvZWQxmQ8
nJFIKfgM4WbWBPQMFXs9KU5FrBJC/iETzlgv7yFfvp1eBfz57fUNAE3ZQ496du9w
VPiXscN7Q49KCz/V/QE59agiokEbLpnVC2q8kjM+ydjSjEUNTvqwJPBek6KQDkHT
rHiEw/JKA+/P3FrDdp2LFtLpKmbgOviqV1+2GtQE16qjA0FfsoA3pK9wJeu21Qtv
vajY+Oe864TFkOJG/fO07RzsTfqA63FfDPtm+s/j18OcISab1DPk6guEmTOTqEsB
RAUMUiMyu7HawCSob6vwYrNsRlU6S/KFnC2AlWOfpBStq1tiEbqo/fGn2I84744o
J6eCyP15qG0NbFt+tF53iEMRVPG+VH40v1nymPbe5xUdcvj6kv/1A46PM0zBmM60
KwzerrOk1WY8TCwx1x7dQh9+mpwL0nFSyKFhIMAbhhYbrH4xxJRdB8AG7o0EpzIg
oAsTwBRBht7FoysoJkW3xpEjbE2L3GCi8CPqnpP8K17sEo98DVhHOff3qq3WmmHf
cCUchnrWh1fvaTKBQ4Fj/kmTejJZvZMLmFXbupa1GvsxkP1+T6cWfGekIwARAQAB
tChEaWVnbyBWYWxsZS1Kb25lcyA8ZGllZ29AZGllZ292YWxsZS5uZXQ+iQJUBBMB
CgA+AhsDBQsJCAcDBRUKCQgLBRYCAwEAAh4BAheAFiEEdrN0z6+56GMTE4OoVb2e
xM2BwlIFAmArwtUFCRJnQa0ACgkQVb2exM2BwlKn9A/9F9vRJrJH7Jtf4j+B5JcL
Q9Ul0YGLk4XzvKyoa/RJVjUeVwMv6V69fdSii5LqtZXUqSSL6aCmvu/+PEKIuIvA
Y3XI6Flt4LVyn9iAp6lqrWmbUcyAjceCsi4tyjRNSCXK84Cdi7qrf3K8miQkIDSb
OtRG/pA+dHQy3IrPV6zYZZaaU+VQXl7Wxrrr/6zfLoQSH9ZeAy8YN7L9pEN3cKYN
ftjzA2V5ZrLUogRbpDK3EXGu2CGGJC45bDjiVkdBftOYlJxUZJh8FS216hl9niaz
0gGMAhKpGTbQ3CTtDwKk/ImVxyrw3+z53PnVX3FM0ykIcvPUKVtotZ/AQPII2qR2
IQ6UYYqXh94bC47Irz7A1z2CmBaPTd2hqjWWBXl4Y27wDjq91QtGcz7HFn/dplG8
7fUkzJBqRUm1GjZZiOHz0TGq83Hz41oIrO0aR90AG9pypX64Tu0hB18Vm+8vmdkx
8bGHBEooP9yys7lAipX9u+OsPI+PdA9GDf5/OIuqVA8R/8uZrVldLCqP/pNNkuVY
sssvV5+yTe8gfZs4StuS47I+7iyEBOekwPqE3ctsKtTXKfb1L/C/4KnyRDzuLUS+
WeI6JNmoVqytIuo8ZPGAMCzoGU+iXt9WnaycWYewH2DyT0FZNrgblN17YEvGtoYi
bqo3Y3B4kv/pucWUzCYL25S0JkRpZWdvIFZhbGxlLUpvbmVzIDxnaXRAZGllZ292
YWxsZS5uZXQ+iQJSBBMBCgA8FiEEdrN0z6+56GMTE4OoVb2exM2BwlIFAmAr6HUC
GwMFCRJnQa0ECwkIBwQVCgkIBRYCAwEAAh4BAheAAAoJEFW9nsTNgcJSPOUQAK/s
R5bgeZ2ApMWRaQi4afZyjTHI7NasSy1MHAwXOHLFGBkINE8pE4BXee25bYt8jDpm
zo8JZtK+lEolQqrqW/Y4B3+b+M9w1mqyYXRVzbsH9ZXBLWrYrpe3mifnlyHjLdAT
t8KZxMZ+ngAdsPv9E1hbdFjDDx00W8SKPhCHAjNNWDjWQVT3HeggNcpGYceFj6IR
eQqNVA+gjeimVF0ggFcKex2YGcMs4JiP5ymu9b6UxuR/QMVTgONDezNJkotAGpME
rQWpdVUNaJOKplEH0p03VlyCJvjzX1r1PxwzPp+szCG1dHRAPtEKZwiXoy1yISZz
JPmwV10X/jO5/ueIuP4I+0zMpDsgogBqLNU+WHx9QiXQ5pNKSUDiV5GILqth8GXv
AEx4J4hM4f5Rga/Pbcz0+eLs5c+awqi62YLvq6Sn+oGHofqaz/0s8qD9eahXvUN9
LU2ESYt05QC42Xyr3nMcz05/IEIk2Cj33yFGO/qHS5ezD/xpOE4aUytQ1WYNrZx4
T9fH7+naxkw4C2ubFA/ihXKnZTq1Fz6dnG2ax1wR1djmmvBcbt4m5DRHPG0SJgf2
9Fal4uTN+3PtTqM86iSU2a/ZK8ijJmxWI0slTGW1hthYXjp+ZSvTjiNeelVYpncT
JftMv1f7D00vRhbyDRlt/2SpfIHHAJAlYmHFTD+ZuQINBFNoG6gBEADuPtweETqY
+2BhlnlOysIzkboA53zbuNtq0FNSnVxni5XMiRcibJ79hCU2Y2DCl29QhEgO/n87
V1UXhKmBJf469gy1OqoBtyYeOIENpaLuuJW4n5exf517Tojzb6eSXu5m7zmCFT1O
wIqqToxpL8SwtXVcFDNMTn8bIEHscaLRI0CUWTY9QXiVPL3x78Z54hMLI/H26Cda
CVfuRMiXKmgi+j0CVuZiW9ff1In0FbEPQfoRLXEmoaFt8uTUa5X5p5GQktXoDVca
ljbp+VChAeJN/OLpSef6PsFacg6Sgg8jt5GhqUCf9jPgwjl5KXyIxvxfJ8B0mgzQ
x+r72Dol+6JvRWH6BkMdjMymaylkEDwFkmJI3aYSghyHgY3fwjZKYnDBLuA5ogpl
YGLSsejhHlHEqPE/PBZUXAoR7TsWIjRg48h4j6RYv2w213U3nZIevHv+R5owTzjK
6qy/i6a3XmhMt5SOtDCqFHUsEdifgdaDR/VEdRmZaDAYarBpBKUKVf2KOwelwGAe
islU44ZiinPULLOqIqjGpkyoEQBEVce1qm/5ATupsdkrCoLG2r2bm6dJez+fk2hl
TDmUTUftS+el32m1p5HoLNRzHGEXXo/Zg0LunoybI7wT5Rwxo86V812fvXCP68MJ
hCQ/mf4uk1frs6LqF8lsfdYwydLlHhErXQARAQABiQI8BBgBCgAmAhsMFiEEdrN0
z6+56GMTE4OoVb2exM2BwlIFAmArww8FCRJnQecACgkQVb2exM2BwlKK1BAAhxPf
NjNJeUzBIaNAGxo86ZXvKxoZRyEFIGcRRjowCXZWH8hp9o1wHpUiMQSrcCMrZolj
5FbmwaaPzO+/B3lQJa44a3WwOtC0Yc0wYJ09DaZKP3TqiUabIoDBg0PPImwRUX+/
MU2cnrsFUF/g5Hrtzt0P6eCizDJgiAOzL5sheGY+h5OZslf6vkp6ttbMpQr9rOuY
H71yVkBa8SOMLLAutAD4X/qnxVxYYuUx3XzBRWW2oOAH+rV0Ss33wL91YErIZb2G
XhFRYIEDHLfVTAlLP9mN02zyVPVls7vH45mWg+SIOQp9c7LdwBJYvoICAOUNtxW5
PufXQe5bfqwC1OSRAbtXpFUhCDSh2IMlgu88Ncnjn1zOD+Bh81D5MOVxfjYSnQaH
/LJYQvxyVm9v3QGfUonTcLqeh+1b0mzknda1ZnzfoQIY3sV5h8gW4LYo9DawcXKV
17t0IgHFmZTEcMdDJGjLUZQKUWy5QYpS1ewduoge18kWvGLUpxOvyCdaRLRuD8/v
n/G9O7IhxZHyyP6KeyIA77nbcSCUWlxlXW1EWSq347zyp0xNAwrk/tcd1pJE2rbJ
rSZFKJwydtv6k79VSeVaQv05xQzBv2ef2TOX6U+7MU4B80UWZhka1PXo6TEMPYf+
W+TXKLALQXSb/mrTQeaauk3TdsQFkQP95Z2xh9Y=
=0HBI
-----END PGP PUBLIC KEY BLOCK-----
         </code> `,
            }}
          />

          <div className="">
            <h4 className="font-bold my-3">
              <Trans>Around the web:</Trans>
            </h4>
            <ul class="max-w-md space-y-1list-none list-inside">
              <li>
                Twitter:{" "}
                <a href="https://twitter.com/diegovalle" rel="me">
                  @diegovalle
                </a>
              </li>
              <li>
                GitHub:{" "}
                <a href="https://github.com/diegovalle" rel="me">
                  diegovalle
                </a>
              </li>
              <li>
                Facebook:{" "}
                <a href="https://facebook.com/diegovalle" rel="me">
                  diegovalle
                </a>
              </li>
              <li>
                Linkedin:{" "}
                <a href="https://www.linkedin.com/in/diegovalle" rel="me">
                  diegovalle
                </a>
              </li>
              <li>
                Gitlab:{" "}
                <a href="https://gitlab.com/diegovalle" rel="me">
                  diegovalle
                </a>
              </li>
              <li>
                Tumblr:{" "}
                <a href="https://diegovalle.tumblr.com/" rel="me">
                  diegovalle
                </a>
              </li>
              <li>
                Vimeo:{" "}
                <a href="https://vimeo.com/diegovalle" rel="me">
                  diegovalle
                </a>
              </li>
              <li>
                Flickr:{" "}
                <a href="https://www.flickr.com/diegovalle/" rel="me">
                  diegovalle
                </a>
              </li>
              <li>
                Youtube:{" "}
                <a href="https://www.youtube.com/@diegovalley" rel="me">
                  @diegovalley
                </a>
              </li>
              <li>
                About.me:{" "}
                <a href="https://about.me/diegovalle" rel="me">
                  diegovalle
                </a>
              </li>
              <li>
                Medium:{" "}
                <a href="https://medium.com/@diegovalle" rel="me">
                  diegovalle
                </a>
              </li>
              <li>
                Manifold:{" "}
                <a href="https://manifold.markets/DiegoValle" rel="me">
                  @DiegoValle
                </a>
              </li>
              <li>
                SoundCloud:{" "}
                <a href="https://soundcloud.com/diegovalle" rel="me">
                  diegovalle
                </a>
              </li>
              <li>
                Pinterest:{" "}
                <a href="https://www.pinterest.com/diegovalley/" rel="me">
                  diegovalley
                </a>
              </li>
            </ul>
          </div>
        </section>
      </main>
    </Layout>
  );
}

export default ContactPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "contact", "header"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
